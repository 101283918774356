import { FC } from 'react';
import Link from 'next/link';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { IAdvertisingData } from '@/interfaces/advertisingBlock';
import { hideImgOnError } from '@/helpers/helpers';
import styles from './ScrollExternalCardItem.module.scss';

interface IScrollExternalCardItemProps {
  book: IAdvertisingData;
}

const ScrollExternalCardItem: FC<IScrollExternalCardItemProps> = ({ book }) => {
  const { litres_url, external_id, title } = book;

  return (
    <div className={styles.scrollCard}>
      <div className={styles.scrollCard__info}>
        <Link prefetch={false} target="_blank" className={styles.scrollCard__imgWrap} href={litres_url}>
          <img loading="lazy" className={styles.scrollCard__img} src={`${DEV_ABSOLUTE_URL}/covers_120/${external_id}.jpg`} width="104" height="148" alt={title} onError={hideImgOnError} />
        </Link>
        <div className={styles.scrollCard__desc}>
          <Link prefetch={false} target="_blank" className={styles.scrollCard__title} href={litres_url}>
            {title}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ScrollExternalCardItem;
