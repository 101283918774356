import { FC } from 'react';
import Link from 'next/link';
import styles from './ScrollAuthorCardItem.module.scss';
import { ITopAuthor } from '@/interfaces/topAuthors.interface';
import { getAuthorFullName, getTruncatedTxt } from '@/helpers/helpers';

interface IScrollAuthorCardItemProps {
  author: ITopAuthor;
}

const ScrollAuthorCardItem: FC<IScrollAuthorCardItemProps> = ({ author }) => {
  const { first_name, middle_name, last_name, id } = author;
  const authorFullName = getAuthorFullName(first_name, middle_name!, last_name);

  return (
    <div className={styles.scrollAuthors__item}>
      <span className={styles.scrollAuthors__imgWrap}></span>
      <Link prefetch={false} className={styles.scrollAuthors__link} href={`/library/author/${id}`}>
        {getTruncatedTxt(authorFullName, 35)}
      </Link>
    </div>
  );
};

export default ScrollAuthorCardItem;
